.pnlm-controls-container {
  display: none !important;
}
.pnlm-hotspot {
  width: 70px !important;
  height: 70px !important;
  background-position: center !important;
  background-size: contain !important;
  background-image: url("data:image/svg+xml,%3Csvg width='70px' height='70px' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_1010_1139)'%3E%3Ccircle cx='28' cy='28' r='20' fill='white'/%3E%3Ccircle cx='28' cy='28' r='18.5' stroke='url(%23paint0_radial_1010_1139)' stroke-width='3'/%3E%3C/g%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M28.5 22H27.5V27.5H22V28.5H27.5V34H28.5V28.5H34V27.5H28.5V22Z' fill='%231E93A1'/%3E%3Cdefs%3E%3Cfilter id='filter0_d_1010_1139' x='0' y='0' width='64' height='64' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dx='4' dy='4'/%3E%3CfeGaussianBlur stdDeviation='6'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.411765 0 0 0 0 0.752941 0 0 0 0 0.709804 0 0 0 0.5 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1010_1139'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1010_1139' result='shape'/%3E%3C/filter%3E%3CradialGradient id='paint0_radial_1010_1139' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(16.4615 14.1538) rotate(60.3956) scale(38.9279)'%3E%3Cstop offset='0.0989583' stop-color='%23B9FCF3'/%3E%3Cstop offset='0.864583' stop-color='%2369C0B5'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E") !important;
  &:hover {
    background-color: transparent !important;
    background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_1010_1237)'%3E%3Ccircle cx='28' cy='28' r='20' fill='%2369C0B5'/%3E%3Ccircle cx='28' cy='28' r='18.5' stroke='url(%23paint0_radial_1010_1237)' stroke-width='3'/%3E%3C/g%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M33.5008 34.4995L34.5008 33.4994L29.0001 27.9987L34.4996 22.4993L33.4995 21.4993L28.0001 26.9987L22.5008 21.4994L21.5008 22.4994L27.0001 27.9987L21.4995 33.4993L22.4995 34.4994L28.0001 28.9987L33.5008 34.4995Z' fill='white'/%3E%3Cdefs%3E%3Cfilter id='filter0_d_1010_1237' x='0' y='0' width='64' height='64' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dx='4' dy='4'/%3E%3CfeGaussianBlur stdDeviation='6'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.411765 0 0 0 0 0.752941 0 0 0 0 0.709804 0 0 0 0.2 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1010_1237'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1010_1237' result='shape'/%3E%3C/filter%3E%3CradialGradient id='paint0_radial_1010_1237' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(16.4615 14.1538) rotate(60.3956) scale(38.9279)'%3E%3Cstop offset='0.0989583' stop-color='white'/%3E%3Cstop offset='0.864583' stop-color='white'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E") !important;
  }
}
div.pnlm-tooltip:hover span:before,
div.pnlm-tooltip:hover span::after {
  display: none;
}
.pnlm-hotspot-base > span {
  position: absolute !important;
  z-index: 0;
  color: black !important;
  background-color: transparent !important;
  margin-left: 1.5rem !important;
  margin-top: 1.5rem !important;
}
.pnlm-hotspot-base > span > div {
  box-shadow: 3px 4px 7px 0 rgb(150 192 181 / 60%) !important;
}

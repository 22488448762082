@tailwind base;
@tailwind components;
@tailwind utilities;

//import style file
@import "../src/assets/style/scss/Loader/loader.scss";
@import "../src/assets/style/scss/View-Description/viewdescription.scss";
@import "../src/assets/style/scss/Panorama/panorma.scss";
//import fonts
@layer base {
  @font-face {
    font-family: "Volvo Novum";
    src: url("../src/assets/fonts/Volvo\ Novum-Light.woff2") format("woff2"),
      url("../src/assets/fonts/Volvo\ Novum-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Volvo Novum";
    src: url("../src/assets/fonts/Volvo\ Novum-Regular.woff2") format("woff2"),
      url("../src/assets/fonts/Volvo\ Novum-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Volvo Novum";
    src: url("../src/assets/fonts/Volvo\ Novum-Medium.woff2") format("woff2"),
      url("../src/assets/fonts/Volvo\ Novum-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
}
body {
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  display: block;
}
h1 {
  font-size: 2em;
}
h2 {
  font-size: 1.5em;
}
h3 {
  font-size: 1.17em;
}
h4 {
  font-size: 1em;
}
h5 {
  font-size: 0.83em;
}
h6 {
  font-size: 0.67em;
}

.progressbar {
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: rotateX(-20deg) rotateY(-15deg);
  -ms-transform: rotateX(-20deg) rotateY(-15deg);
  -moz-transform: rotateX(-20deg) rotateY(-15deg);
  -webkit-transform: rotateX(-20deg) rotateY(-15deg);
}

.bottom {
  transform: rotateX(90deg);
}
.top {
  transform: translate(0, -100%) rotateX(90deg);
}
.back {
  transform: translateZ(-56px);
}

.left {
  transform: rotateY(90deg);
}
